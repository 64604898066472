import * as React from 'react'
import { useSelector } from 'react-redux'
import { Global } from '@emotion/core'
import { node } from 'prop-types'

function ThemeWrapper({ children }) {
	const { styles } = useSelector(state => state.user)
	return (
		<>
			{styles && <Global styles={styles} />}
			{children}
		</>
	)
}

ThemeWrapper.propTypes = {
	children: node.isRequired
}

export default ThemeWrapper
